import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
        <path
          // d="M 58.17 79 L 52.22 79 L 36.61 55.13 L 51.87 30 L 57.89 30 L 42.63 55.13 L 58.17 79 Z M 33.6 79 L 28 79 L 28 30 L 33.6 30 L 33.6 79 Z"
          d="M 34.9 46.3 L 44.05 30 L 50.1 30 L 40.5 45.35 L 50.8 63 L 44.4 63 L 34.9 46.3 Z M 33.85 30 L 33.85 63 L 28 63 L 28 30 L 33.85 30 Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

//font Sansita 50
//https://danmarshall.github.io/google-font-to-svg-path/
//offset 28 30
//https://yqnn.github.io/svg-path-editor/
export default IconLogo;
